/* eslint-disable react/prop-types */

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Alert, Button, SelectInput, TextField,
} from '@deloitte/gel-library'
import { filter, max, size } from 'lodash'
import classnames from 'classnames'
import { removeByKey } from 'utils/objects'
import DateInput from 'components/Form/components/DateInputNew'
import IconDeleteBin from 'icons/IconDeleteBin'
import IconError from 'icons/IconError'
import styles from './TableInput.scss'

const InputComponent = ({
  row,
  disabled,
  error,
  column,
  name,
  value,
  type,
  options,
  onChange,
  onBlur,
  onFocus,
  placeholder,
}) => {
  const errorString = error && !error.valid ? error.error : null

  switch (type) {
    case 'DATE':
      return (
        <DateInput
          date={value}
          disabled={disabled}
          error={errorString}
          handleChange={(_name, _value) => onChange(row, column, _value)}
          name={name}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      )
    case 'COMBOBOX':
      return (
        <span title={value}>
          <SelectInput
            floatedLabel
            className={styles.selectInput}
            disabled={disabled}
            label=""
            name={name}
            onBlur={onBlur}
            onChange={(_, _value) => onChange(row, column, _value.value)}
            options={options.map(o => ({ label: o, value: o }))}
            searchable={false}
            value={value}
          />
        </span>

      )
    case 'INT':
    case 'FLOAT':
      return (
        <TextField
          className={styles.field}
          disabled={disabled}
          error={errorString}
          label=""
          name={name}
          onBlur={onBlur}
          onChange={(_name, _value) => onChange(row, column, _value)}
          placeholder={placeholder}
          type="text"
          value={value}
        />
      )
    default:
      return (
        <TextField
          className={styles.field}
          disabled={disabled}
          error={errorString}
          label=""
          name={name}
          onBlur={onBlur}
          onChange={(_name, _value) => onChange(row, column, _value)}
          placeholder={placeholder}
          type="text"
          value={value}
        />
      )
  }
}

export default class TableInput extends Component {
  static propTypes = {
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
    schema: PropTypes.arrayOf(
      PropTypes.shape({
        default: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string,
        placeholder: PropTypes.string,
        type: PropTypes.string,
      }),
    ).isRequired,
    value: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.number,
      }),
    ).isRequired,
  }

  update = (row, column, value, updatedError) => {
    const {
      error, name, onBlur, onChange,
    } = this.props

    onChange(name, value)
    onBlur(name, value, {
      row,
      column,
      priorError: updatedError || error,
    })
  }

  addRow = () => {
    const { schema, value } = this.props

    const key = max(value.map(row => row.key)) + 1 || 0

    const newRow = { key }
    schema.forEach((column) => {
      newRow[column.name] = column.default
    })

    const newValue = [...value, newRow]

    this.update(null, null, newValue)
  }

  deleteRow = (key) => {
    const { error, value } = this.props
    const newRows = value.filter(row => row.key !== key)
    const newError = removeByKey(error, `${key}`)

    this.update(null, null, newRows, newError)
  }

  handleChange = (inputRow, inputColumn, inputValue) => {
    const { value } = this.props

    const newValue = value.map(row => ({
      ...row,
      [inputColumn]: row.key === inputRow ? inputValue : row[inputColumn],
    }))

    this.update(inputRow, inputColumn, newValue)
  }

  handleFocus = () => {
    const { name, onFocus } = this.props

    onFocus(name)
  }

  handleBlur = (row, column) => {
    const {
      name, value, onBlur, error,
    } = this.props

    onBlur(name, value, { row, column, priorError: error })
  }

  renderAddButton = () => (
    <Button mode="flat" onClick={this.addRow}>
      + Add
    </Button>
  )

  render() {
    const { value, schema, error } = this.props

    const errorCount = size(filter(error, e => !e.valid))
    const errorPlural = errorCount === 1 ? '' : 's'

    if (value.length === 0) {
      return (
        <div className={styles.base}>
          <div className={styles.emptyTable}>
            <this.renderAddButton />
          </div>
        </div>
      )
    }

    return (
      <div className={styles.base}>
        {errorCount > 0 && (
          <div
            className={classnames(styles.tableError, { [styles.hidden]: !error })}
          >
            <Alert showing={!!error} type="error">
              {`Errors detected in ${errorCount} row${errorPlural}`}
            </Alert>
          </div>
        )}
        <table>
          <thead>
            <tr>
              <th />
              {schema.map(column => (
                <th key={column.name}>{column.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {value.map((row, index) => (
              <tr key={row.key}>
                <td className={styles.delete}>
                  <IconDeleteBin
                    height={18}
                    onClick={() => this.deleteRow(row.key)}
                    width={18}
                  />
                  {error[row.key]
                    && !error[row.key].valid && (
                      <IconError
                        className={styles.rowError}
                        height={11}
                        width={11}
                      />
                  )}
                </td>

                {schema.map(column => (
                  <td key={column.name}>
                    <InputComponent
                      column={column.name}
                      error={
                        error[row.key] ? error[row.key][column.name] : null
                      }
                      name={`${index}_${column.name}`}
                      onBlur={() => this.handleBlur(row.key, column.name)}
                      onChange={this.handleChange}
                      onFocus={this.handleFocus}
                      options={column.options}
                      placeholder={column.placeholder}
                      row={row.key}
                      type={column.type}
                      value={row[column.name] || ''}
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={schema.length + 1}>
                <this.renderAddButton />
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    )
  }
}
